import { get } from 'services';
import routes from './routes';

const BillServices = {
  getBills: async (params) => {
    const data = await get(routes.getBills, params);
    return data;
  },
  getBillDetailById: async (params) => {
    const data = await get(routes.getBillDetailById, params);
    return data;
  },
}

export default BillServices