export const Images = {
  logo: require("./Images/logo.png"),
  authBanner: require("./Images/auth-banner.jpg"),
  kgcRules: require("./Images/KGC-Rules.pdf"),
};

export const Loader = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#fff" stroke="none">
        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51" />
      </path>
    </svg>
  )
}

export const EyeIcon = () => {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.7604 8.82496C14.7471 7.73962 15.3391 6.62962 15.3391 5.98829C15.3391 4.53296 12.0337 0.265625 7.93906 0.265625C3.8444 0.265625 0.539062 4.53296 0.539062 5.98829C0.539062 7.44362 3.8444 11.711 7.93906 11.711C9.07373 11.711 10.1591 11.3903 11.1211 10.8723L13.7604 12.599V8.82496Z" fill="#27DEBF" />
      <path d="M7.93828 10.1305C9.98173 10.1305 11.6383 8.47392 11.6383 6.43047C11.6383 4.38702 9.98173 2.73047 7.93828 2.73047C5.89483 2.73047 4.23828 4.38702 4.23828 6.43047C4.23828 8.47392 5.89483 10.1305 7.93828 10.1305Z" fill="white" />
      <path d="M7.9407 4.22266C7.69404 4.22266 7.47204 4.27199 7.25004 4.32132C7.52137 4.54332 7.69404 4.88866 7.69404 5.25866C7.69404 5.94932 7.15137 6.49199 6.4607 6.49199C6.18937 6.49199 5.9427 6.39332 5.7207 6.24532V6.41799C5.7207 7.65132 6.70737 8.63799 7.9407 8.63799C9.17404 8.63799 10.1607 7.65132 10.1607 6.41799C10.1607 5.18466 9.17404 4.22266 7.9407 4.22266Z" fill="#27DEBF" />
    </svg>
  )
}

export const SearchIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.37331 16.7915C13.138 16.7915 16.19 13.7396 16.19 9.97487C16.19 6.21013 13.138 3.1582 9.37331 3.1582C5.60857 3.1582 2.55664 6.21013 2.55664 9.97487C2.55664 13.7396 5.60857 16.7915 9.37331 16.7915Z" stroke="#D6D7E3" strokeWidth="1.70417" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.8941 18.4956L14.1875 14.7891" stroke="#D6D7E3" strokeWidth="1.70417" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}