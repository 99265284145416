import React, { lazy } from "react";

const Dashboard = lazy(() => import('pages/Dashboard'));
const Bills = lazy(() => import('pages/Bills'));
const Handicap = lazy(() => import('pages/Handicap'));
const News = lazy(() => import('pages/News'));
const ChangePassword = lazy(() => import('pages/Auth/ChangePassword'));

const MemberRoutes = [
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: "/bills",
    component: <Bills />,
  },
  {
    path: "/handicap",
    component: <Handicap />,
  },
  {
    path: "/news",
    component: <News />,
  },
  {
    path: "/change-password",
    component: <ChangePassword />,
  },
]

export default MemberRoutes
