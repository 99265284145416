import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { useAuth } from 'context/UseContext';
import Colors from 'assets/Style/Colors';
import { ToasterContainer } from 'components/Toaster';
import AuthRoutes from 'routes/AuthRoutes';
import MemberRoutes from 'routes/MemberRoutes';
import WebsiteLayout from 'layouts/Website';
import DashboardLayout from 'layouts/Dashboard';
import ScrollToTop from 'hooks/ScrollToTop';
import BillDetail from 'pages/Bills/Details';

let theme = createTheme();
theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.secondary,
      contrastText: Colors.white,
    },
    danger: {
      main: Colors.danger,
      contrastText: Colors.white,
    },
  },
  typography: {
    fontFamily: ["Poppins"],
    h5: {
      fontSize: 24,
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
      },
    },
    h6: {
      fontSize: 20,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 500
    },
    body1: {
      fontSize: 14,
      fontWeight: 500
    },
    body2: {
      fontSize: 12,
      fontWeight: 400
    },
    caption: {
      fontSize: 10,
      fontWeight: 300
    },
  },
});

function App() {

  const { user } = useAuth();

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>

        {/* ========== Toaster ========== */}
        <ToasterContainer />

        <Routes>

          <Route path={"/bills/member-bill"} element={<BillDetail />} />

          <Route element={user?.token ? <Navigate to={"/"} /> : <WebsiteLayout />} >
            {AuthRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          <Route element={user?.token ? <DashboardLayout /> : <Navigate to="/auth/login" />} >
            {MemberRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

        </Routes>

        {/* ========== Scroll To Top ========== */}
        <ScrollToTop />

      </ThemeProvider>
    </BrowserRouter >
  );
}

export default App;
