import axios from 'axios';

export const baseUrl = 'https://karachigolf.com.pk/KarachiGolf_Apis/public/api/'

const instance = axios.create({
  baseURL: baseUrl
});

instance.interceptors.request.use((request) => {

  let user = JSON.parse(localStorage.getItem('user'))

  request.headers = {
    'Accept': "application/json, text/plain, */*",
    'Authorization': `Bearer ${user?.token}`,
  }
  return request
});

instance.interceptors.response.use((response) => {
  if (response) {

    return response
  }
}, (error) =>
  Promise.reject(
    error
  )
);

export default instance;